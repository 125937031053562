import {
	HStack,
	Divider,
	Stack,
	Box,
	Icon,
	Slider,
	SliderFilledTrack,
	SliderTrack,
	Tag,
	Text,
	Tooltip,
	useToast,
	IconButton,
} from '@chakra-ui/react'
import React from 'react'
import { FiCopy } from 'react-icons/fi'
import { MdDateRange, MdTimelapse, MdAccessTime } from 'react-icons/md'
import { IoIosArrowForward } from 'react-icons/io'
import Card from '../../card'
import { format } from 'date-fns'
import { toSnakeCase } from '../../../utils/common.util'
import { useAuthContext } from './../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	GA_CATEGORY_FILES,
// 	sectionDetailedTrack,
// } from './../../../containers/repository/repo.utils'
import { BsInfoCircle } from 'react-icons/bs'

const Activity = ({
	activity,
	selectedActivity,
	onSelect,
	loadChildOrders,
	selectedQueue,
	selectedFilter,
	selectedActivities = [],
	searchQuery = '',
	openParticipants = () => null,
}) => {
	const toast = useToast({
		duration: 1000,
	})
	const {
		activity_lead_operating_asset_first_name,
		activity_title,
		operating_asset_first_name,
		activity_type_category_name,
		activity_type_name,
		activity_status_name,
		activity_cuid_1,
		activity_cuid_2,
		activity_cuid_3,
		account_name,
		activity_datetime_created,
		activity_datetime_start_expected,
		activity_datetime_end_deferred,
		activity_datetime_end_status,
		asset_unread_updates_count,
		asset_unread_mention_count,
		activity_workflow_completion_percentage: completion,
		activity_sub_type_id,
		activity_creator_operating_asset_first_name,
		activity_id,
		activity_lead_asset_id,
	} = activity

	const partInfo = {
		activity_id,
		activity_lead_asset_id,
		activity_title,
	}

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const loadUserName = name => {
		if (name) {
			let nameArray = name?.split(' ')
			if (nameArray.length > 1) {
				return `${nameArray[0]} ${
					nameArray[1] && nameArray[1][0].toUpperCase()
				}.`
			} else if (nameArray.length > 0) {
				return `${nameArray[0]}.`
			}
		}
		return ''
	}

	const timeColor = datetime => {
		let isDelayed =
			new Date(datetime?.split(' ')?.join('T')).setHours(0, 0, 0, 0) <
			new Date().setHours(0, 0, 0, 0)
		if (!!isDelayed) {
			return 'red'
		} else {
			return ''
		}
	}

	if (selectedFilter && Object.keys(selectedFilter).length) {
		return (
			<Card
				className={'activity-card'}
				boxShadow='md'
				borderRadius='md'
				p={2}
				maxW='300px'
				bg={
					!!selectedActivity &&
					selectedActivity.activity_id === activity.activity_id
						? 'white'
						: !!activity?.activity_priority_enabled
						? '#e4f9e9'
						: 'secondary'
				}
				border={
					!!selectedActivity &&
					selectedActivity.activity_id === activity.activity_id
						? '1px'
						: 'none'
				}
				borderColor={localStorage.getItem('color')}
				_hover={{
					boxShadow: 'lg',
				}}
				cursor='not-allowed'
			>
				<Tooltip title={activity_title}>
					<Box
						className={`activity-card-title-${toSnakeCase(activity_title)}`}
						fontWeight='500'
						fontSize='16px'
						mb={1}
						textOverflow='ellipsis'
						color='brand.900'
					>
						<HStack justifyContent='space-between'>
							<Text as='span'>
								<Text as='span' textTransform='capitalize'>
									{activity_title}
								</Text>
								<Icon
									as={FiCopy}
									onClick={e => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FILES,
										// 	action: 'Activity',
										// 	label: 'Activity Title Copied',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Workflow List',
										// 	buttonName: 'Activity Title Copy',
										// })
										navigator.clipboard.writeText(activity_title)
										e.stopPropagation()
										toast({
											title: 'Copied!',
											status: 'success',
										})
									}}
									w={4}
									h={4}
								/>
							</Text>
							<IconButton
								bg='white'
								aria-label='close'
								size='sm'
								borderRadius='md'
								zIndex='1000'
								boxShadow='md'
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								icon={
									<Icon
										className={`field-item-info-icon`}
										as={BsInfoCircle}
										w={4}
										h={4}
									/>
								}
								onClick={e => {
									openParticipants(partInfo)
									e.stopPropagation()
								}}
							/>
						</HStack>
					</Box>
				</Tooltip>
				{activity_type_name && (
					<Text
						mb={1}
						fontSize='12px'
						className={'activity-card-category-name'}
					>
						{locale['Type']} : <Text as='span'>{activity_type_name}</Text>
					</Text>
				)}
				{activity_cuid_1 && (
					<Text mb={1} fontSize='12px'>
						{locale['CUID 1']}: <Text as='span'>{activity_cuid_1}</Text>{' '}
						<Icon
							as={FiCopy}
							onClick={e => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FILES,
								// 	action: 'Activity',
								// 	label: 'CUID 1 Copied',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Workflow List',
								// 	buttonName: 'CUID 1 Copy',
								// })
								navigator.clipboard.writeText(activity_cuid_1)
								e.stopPropagation()
								toast({
									title: 'Copied!',
									status: 'success',
								})
							}}
							w={4}
							h={4}
						/>{' '}
					</Text>
				)}
				{activity_cuid_2 && (
					<Text mb={1} fontSize='12px'>
						{locale['CUID 2']} : <Text as='span'>{activity_cuid_2}</Text>
					</Text>
				)}
				{activity_cuid_3 && (
					<Text mb={1} fontSize='12px'>
						{locale['CUID 3']} : <Text as='span'>{activity_cuid_3}</Text>
					</Text>
				)}
				{account_name && (
					<Text mb={1} fontSize='12px'>
						{locale['Circle']} : <Text as='span'>{account_name}</Text>
					</Text>
				)}
				<Text mb={1} fontSize='12px'>
					{locale['Creator']} :{' '}
					<Text as='span'>
						{loadUserName(activity_creator_operating_asset_first_name)}
					</Text>
				</Text>
			</Card>
		)
	}

	return (
		<>
			<Card
				className={'activity-card'}
				boxShadow='md'
				borderRadius='md'
				p={2}
				maxW='300px'
				bg={
					!!selectedActivity &&
					selectedActivity.activity_id === activity.activity_id
						? 'white' //green.50
						: !!activity?.activity_priority_enabled
						? '#e4f9e9'
						: 'secondary'
				}
				border={
					!!selectedActivity &&
					selectedActivity.activity_id === activity.activity_id
						? '1px'
						: 'none'
				}
				borderColor={localStorage.getItem('color')}
				cursor='pointer'
				_hover={{
					boxShadow: 'lg',
				}}
				onClick={() => {
					// eventLabelTrack({
					// 	categoryId: 0,
					// 	actionId: 1,
					// 	label: `Activity Type Name: ${activity.activity_type_name}`,
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Workflow List',
					// 	buttonName: 'Select Workflow',
					// })
					onSelect(activity)
				}}
				onTap={() => {
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Workflow List',
					// 	buttonName: 'Select Workflow',
					// })
					onSelect(activity)
				}}
			>
				<Tooltip title={activity_title}>
					<Box
						className={`activity-card-title-${toSnakeCase(activity_title)}`}
						fontWeight='500'
						fontSize='16px'
						mb={1}
						textOverflow='ellipsis'
						color='brand.900'
					>
						<HStack justifyContent='space-between'>
							<Text as='span'>
								<Text
									as='span'
									textTransform='capitalize'
									wordBreak='break-all'
								>
									{activity_title}
								</Text>
								<Icon
									as={FiCopy}
									onClick={e => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FILES,
										// 	action: 'Activity',
										// 	label: 'Activity Title Copied',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Workflow List',
										// 	buttonName: 'Activity Title Copy',
										// })
										navigator.clipboard.writeText(activity_title)
										e.stopPropagation()
										toast({
											title: 'Copied!',
											status: 'success',
										})
									}}
									w={4}
									h={4}
								/>
							</Text>
							{activity_sub_type_id === 1 && (
								<Icon
									zIndex={50}
									as={IoIosArrowForward}
									onClick={e => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FILES,
										// 	action: 'Activity',
										// 	label: 'Load Child Orders',
										// })
										loadChildOrders(activity)
										e.stopPropagation()
									}}
									w={4}
									h={4}
								/>
							)}
						</HStack>
					</Box>
				</Tooltip>
				<HStack w={'full'} justifyContent={'space-between'}>
					{activity_status_name && (
						<Tag
							className={'activity-card-tag-name'}
							// variant="outline"
							borderRadius='full'
							border='1px solid'
							// borderColor="#ff0000"
							borderColor={localStorage.getItem('color')}
							// color="#003f72"
							//  colorScheme="green"
							color={localStorage.getItem('color')}
							size='md'
							fontSize='12px'
							mb={1}
						>
							{activity_status_name}
						</Tag>
					)}
					{/* {!!searchQuery && (
              <IconButton
                bg="white"
                aria-label="close"
                size="sm"
                borderRadius="md"
                zIndex="1000"
                boxShadow="md"
                _focus={{
                  bg: 'secondary',
                }}
                _hover={{
                  bg: 'secondary',
                }}
                icon={
                  <Icon
                    className={`field-item-info-icon`}
                    as={BsInfoCircle}
                    w={4}
                    h={4}
                  />
                }
                onClick={e => {
                  openParticipants(partInfo)
                  e.stopPropagation()
                }}
              />
            )} */}
				</HStack>
				<Text mb={1} fontSize='12px' className={'activity-card-category-name'}>
					{activity_type_category_name} :{' '}
					<Text as='span'>{activity_type_name}</Text>
				</Text>
				{activity_cuid_1 && (
					<Text mb={1} fontSize='12px'>
						{locale['CUID']} : <Text as='span'>{activity_cuid_1}</Text>{' '}
						<Icon
							as={FiCopy}
							onClick={e => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FILES,
								// 	action: 'Activity',
								// 	label: 'CUID Copied',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Workflow List',
								// 	buttonName: 'CUID Copy',
								// })
								navigator.clipboard.writeText(activity_cuid_1)
								e.stopPropagation()
								toast({
									title: 'Copied!',
									status: 'success',
								})
							}}
							w={4}
							h={4}
						/>{' '}
					</Text>
				)}
				{/* <HStack> */}
				<Text mb={1} fontSize='12px'>
					{locale['Creator']} :{' '}
					<Text as='span'>
						{loadUserName(activity_creator_operating_asset_first_name)}
					</Text>
				</Text>
				{activity_lead_operating_asset_first_name && (
					<Text mb={1} fontSize='12px'>
						{locale['Lead']} :{' '}
						<Text as='span'>
							{loadUserName(activity_lead_operating_asset_first_name)}
						</Text>
					</Text>
				)}
				{/* </HStack> */}
				<HStack spacing={2} mb={1}>
					<HStack spacing={2} flex={1}>
						<Slider
							aria-label='slider-ex-2'
							isDisabled={true}
							defaultValue={completion || 0}
						>
							<SliderTrack height={2} borderRadius='full'>
								<SliderFilledTrack bg='orange.600' />
							</SliderTrack>
						</Slider>
						<Text color={localStorage.getItem('color')}>
							{!!completion ? `${completion}%` : `0%`}
						</Text>
					</HStack>
					{!!asset_unread_updates_count &&
						!selectedActivities.filter(e => e === activity.activity_id).length >
							0 && (
							<Tag
								borderRadius='full'
								bg={localStorage.getItem('color')}
								color='white'
							>
								{asset_unread_updates_count}
							</Tag>
						)}
					{!!asset_unread_mention_count && (
						<Tag borderRadius='full' colorScheme='#f8b019' color='white'>
							{asset_unread_mention_count}
						</Tag>
					)}
				</HStack>
				<HStack mb={1} justifyContent='flex-start'>
					<Text fontSize='12px' d='flex'>
						<Icon as={MdDateRange} w={4} h={4} />{' '}
						{!!activity_datetime_start_expected
							? format(
									new Date(
										activity_datetime_start_expected?.split(' ')?.join('T') +
											(activity_datetime_start_expected?.includes('00Z')
												? ''
												: '.00Z')
									),
									'dd MMM yyyy'
							  )
							: format(
									new Date(
										activity_datetime_start_expected?.split(' ')?.join('T') +
											(activity_datetime_start_expected?.includes('00Z')
												? ''
												: '.00Z')
									),
									'dd MMM yyy'
							  )}
					</Text>
					{!!activity_datetime_end_deferred && (
						<Text
							fontSize='12px'
							d='flex'
							color={timeColor(activity_datetime_end_deferred)}
						>
							<Icon as={MdTimelapse} w={4} h={4} />{' '}
							{activity_datetime_end_deferred?.includes('date') ||
							activity_datetime_end_deferred?.includes('0000-00-00')
								? ' NA'
								: format(
										new Date(
											activity_datetime_end_deferred?.split(' ')?.join('T') +
												(activity_datetime_end_deferred.includes('00Z')
													? ''
													: '.00Z')
										),
										'dd MMM yyyy'
								  )}
						</Text>
					)}
					{!!activity_datetime_end_status &&
						authData.organization_ai_bot_enabled === 1 && (
							<Text
								fontSize='12px'
								d='flex'
								color={timeColor(activity_datetime_end_status)}
							>
								<Icon as={MdAccessTime} w={4} h={4} />{' '}
								{activity_datetime_end_status?.includes('date') ||
								activity_datetime_end_status?.includes('0000-00-00')
									? activity_datetime_end_status
									: format(
											new Date(
												activity_datetime_end_status?.split(' ')?.join('T') +
													(activity_datetime_end_status.includes('00Z')
														? ''
														: '.00Z')
											),
											'dd MMM yyyy'
									  )}
							</Text>
						)}
				</HStack>
			</Card>
			<Divider />
		</>
	)
}

export default Activity
