/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
	GA_CATEGORY_FILES,
	sectionDetailedTrack,
} from '../../../containers/repository/repo.utils'
import { useAuthContext } from '../../../context/auth.context'
import { useSharedContext } from '../../../context/shared.context'
import { useLanguageContext } from '../../../context/locale.context'
import {
	useCloneActivty,
	useGetFormRateLimit,
	useLoadActivityStatusList,
	useLoadActivityTypeList,
} from '../../../hooks/activity.hooks'
import {
	useLoadAllFormList,
	useLoadAllWorkflowForms,
	useLoadAssetLevelFormList,
	useLoadStatusBasedFormList,
} from '../../../hooks/form.hooks'
import { sharedConst } from '../../../utils/action.constant'

function ActivityClone({ isOpen, onClose, activity, isDashboard }) {
	const { activity_title, activity_type_id } = activity

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch } = useSharedContext()

	const { mutate: mutateCloneActivity, isLoading } = useCloneActivty()
	const { mutate: mutateAssetBasedFormList } = useLoadAssetLevelFormList()
	const { mutate: mutateStatusBasedFormList } = useLoadStatusBasedFormList()
	const { mutate: mutateSubmittedFormList } = useLoadAllFormList()
	const { mutate: mutateWorkflowFormList } = useLoadAllWorkflowForms()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()

	const [cloneActivityName, setcloneActivityName] = useState(
		activity ? `Clone - ${activity_title}` : null
	)
	const [rateLimit, setRateLimit] = useState(1)
	const { mutate } = useGetFormRateLimit()
	useEffect(() => {
		mutate(
			{
				activity_type_id: activity_type_id,
			},
			{
				onSuccess: data => {
					console.log(!!data.current_rate, 'current_rate')
					if (!!data.current_rate) {
						setRateLimit(data.current_rate)
					}
				},
				onError: err => {
					console.log('error occurred', err)
				},
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activity])

	useEffect(() => {
		setcloneActivityName(`Clone - ${activity_title}`)
	}, [activity])

	const createClone = async () => {
		const {
			activity_id,
			activity_type_id,
			activity_type_category_id,
			parent_status_id,
			activity_status_id,
			activity_type_flag_control_visibility,
			activity_creator_asset_id,
			activity_lead_asset_id,
			activity_master_data,
			isOwner,
		} = activity

		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					const activityStatus = data
					mutateActivityTypes(
						{ catId: 9 },
						{
							onSuccess: async data => {
								const activityType = data
								mutateSubmittedFormList(
									{
										statusBasedForms: {
											activity_status_id:
												parent_status_id || activity_status_id,
											workflow_activity_id: activity_id,
											activity_type_category_id,
											flag: 0,
										},
										submittedForms: {
											flag: 1,
											form_id: 0, // form id should be 0 to load all forms
											activity_id,
											activity_type_id,
											activity_type_category_id,
										},
									},
									{
										onSuccess: async data => {
											const submittedFormList = data
											mutateStatusBasedFormList(
												{
													activity_status_id:
														parent_status_id || activity_status_id,
													workflow_activity_id: activity_id,
													activity_type_category_id,
													flag: 0,
												},
												{
													onSuccess: async data => {
														const statusBasedFormList = data
														mutateAssetBasedFormList(
															{},
															{
																onSuccess: async data => {
																	const assetBasedFormList = data
																	let form_fill_request
																	if (activity_master_data) {
																		form_fill_request =
																			JSON.parse(
																				activity_master_data
																			).form_fill_request
																	}
																	const visiblity = {
																		activity_control_visibility:
																			!!activity_type_flag_control_visibility
																				? activity_type_flag_control_visibility
																				: 0,
																		activity_creator_asset_id,
																		form_fill_request,
																		isOwner:
																			!isOwner &&
																			activity_creator_asset_id ===
																				authData.asset_id
																				? true
																				: false,
																		activity_lead_asset_id:
																			activity_lead_asset_id || 0,
																		operating_asset_id:
																			authData.operating_asset_id,
																		asset_id: authData.asset_id,
																		isWidgetActivityWorkspace: isDashboard,
																	}
																	mutateWorkflowFormList(
																		{
																			activity,
																			submittedFormList,
																			statusBasedFormList,
																			assetBasedFormList,
																			visiblity,
																		},
																		{
																			onSuccess: data => {
																				let originForm = data.find(res => {
																					return (
																						res.form_flag_workflow_origin === 1
																					)
																				})
																				let workflowForm = originForm
																				if (!originForm) {
																					workflowForm = {
																						form_id: activity.form_id,
																						form_workflow_activity_type_id:
																							activity.activity_type_id,
																					}
																				}
																				mutateCloneActivity(
																					{
																						activity,
																						cloneActivityName,
																						authData,
																						workflowForm,
																						activityType,
																						activityStatus,
																					},
																					{
																						onSuccess: data => {
																							dispatch({
																								type: sharedConst.REFRESH_LISTING,
																							})
																							onClose()
																						},
																					}
																				)
																			},
																		}
																	)
																},
															}
														)
													},
												}
											)
										},
									}
								)
							},
						}
					)
				},
			}
		)
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent height={Number(rateLimit) === 0 ? '150px' : ''}>
				<ModalHeader fontSize='lg' fontWeight='400'>
					{locale['Clone Activity']}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{Number(rateLimit) === 0 ? (
						<Text color='tomato'>
							Your form submission limit has been reached
						</Text>
					) : (
						<>
							<FormControl id='activity-name' isRequired>
								<Input
									value={cloneActivityName}
									onChange={e => setcloneActivityName(e.target.value)}
									placeholder={locale['Activity name']}
								/>
							</FormControl>
							<Button
								mt={4}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FILES,
										action: 'Activity',
										label: 'Clone Activity',
									})
									createClone()
								}}
								type='submit'
								float='right'
								isLoading={isLoading}
							>
								{locale['Submit']}
							</Button>
						</>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ActivityClone
