/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, Skeleton, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useState, useCallback, useEffect } from 'react'
import { useSharedContext } from '../../context/shared.context'
import { useAuthContext } from '../../context/auth.context'
import {
	useLoadActivityDetails,
	useLoadActivityTypeDetails,
} from '../../hooks/activity.hooks'
import { sharedConst, smartFormConst } from '../../utils/action.constant'
import Activity from './activity'
import DueDateEditModal from '../due-date-edit-modal'
import { useLanguageContext } from '../../context/locale.context'
import { useSmartFormContext } from '../../context/smart-form.context'
import ParticipantsView from '../form/components/Reference/WorkflowReference/ParticipantsView'
const ActivityList = ({
	data,
	isLoading,
	openActivityView,
	selectedQueue,
	loadChildOrders,
	selectedFilter,
	searchQuery,
}) => {
	const { state, dispatch } = useSharedContext()
	const [currentActivity, setCurrentActivity] = useState(null)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [count, setCount] = useState(0)
	const {
		state: {
			authData: { asset_id, asset_flag_super_admin },
		},
	} = useAuthContext()
	const [selectedActivities, setSelectedActivities] = useState([])
	const { mutate } = useLoadActivityDetails()
	const { mutate: mutateActivityTypeDetails } = useLoadActivityTypeDetails()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { isModelOpen },
		dispatch: smartDispatch,
	} = useSmartFormContext()

	const loadDetails = useCallback(activity => {
		mutate(
			{
				activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async details => {
					const isLead = asset_id === details.activity_lead_asset_id
					const isOwner = asset_flag_super_admin
					dispatch({
						type: sharedConst.ACTVITY_SELECTED,
						payload: {
							...details,
							isLead,
							isOwner,
							asset_flag_is_owner: !!activity.asset_flag_is_owner,
						},
					})
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	})

	const loadActivityTypeDetails = useCallback(activity => {
		mutateActivityTypeDetails(
			{ activity_type_id: activity.activity_type_id },
			{
				onSuccess: async data => {
					dispatch({
						type: sharedConst.ACTIVITY_TYPE_DETAILS,
						payload: {
							...data,
						},
					})
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	})

	useEffect(() => {
		setCount(count + 1)
	}, [data])

	const checkActivity = activity => {
		const {
			activity_creator_asset_id,
			activity_lead_asset_id,
			asset_notification_muted,
		} = activity

		const auth_asset_id = asset_id
		const what_next_access = [
			activity_creator_asset_id,
			activity_lead_asset_id,
		].includes(auth_asset_id)

		dispatch({
			type: sharedConst.SET_PARTICIPANT_ACCESS_ID,
			accessID: activity.asset_participant_access_id,
		})
		dispatch({
			type: sharedConst.INITIAL_TIME_LINE_FILTER,
		})
		openActivityView()
		loadDetails(activity)
		loadActivityTypeDetails(activity)
		setSelectedActivities(e => [...new Set([...e, activity.activity_id])])
		dispatch({
			type: sharedConst.RESET_TO_TIMELINE,
			openTeamCollab: true,
		})
		dispatch({
			type: sharedConst.HANDLE_WHATSNEXT,
			payload: what_next_access,
			asset_notification_muted,
		})
		if (selectedQueue.queue_id === 'DELAYED') {
			setCurrentActivity(activity)
			onOpen()
		}
	}

	const openParticipants = data => {
		smartDispatch({ type: smartFormConst.ON_MODEL_OPEN, activityId: data })
	}

	if (!!isLoading) {
		return (
			<VStack
				h='calc(100% - 160px)'
				overflowY='scroll'
				w='full'
				bg='white'
				py={2}
			>
				{[1, 2, 3, 4].map(i => {
					return (
						<Skeleton
							boxShadow='md'
							borderRadius='md'
							key={i}
							p={2}
							w='300px'
							h='250px'
						/>
					)
				})}
			</VStack>
		)
	}

	return (
		<VStack
			h='calc(100% - 160px)'
			overflowY='scroll'
			w='full'
			bg='white'
			py={2}
		>
			{data && data.length > 0 ? (
				data.map(a => {
					return (
						<Activity
							selectedQueue={selectedQueue}
							selectedFilter={selectedFilter}
							activity={a}
							key={Math.random().toString() + a.activity_id}
							selectedActivity={state.activity}
							onSelect={activity => checkActivity(activity)}
							selectedActivities={selectedActivities}
							loadChildOrders={loadChildOrders}
							searchQuery={searchQuery}
							openParticipants={openParticipants}
						/>
					)
				})
			) : (
				<Flex alignItems='center' justifyContent='center'>
					<Text>{locale['No content found!']}</Text>
				</Flex>
			)}
			<DueDateEditModal
				activity={currentActivity}
				isOpen={isOpen}
				onClose={() => {
					onClose()
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				}}
			/>
			{isModelOpen && <ParticipantsView />}
		</VStack>
	)
}

export default ActivityList
