import {
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Collapse,
} from '@chakra-ui/react'
import { useLanguageContext } from './../../context/locale.context'

const EASScores = ({ asset_arp_data }) => {
  const {
    state: { locale },
  } = useLanguageContext()

  const getTableRow = () => {
    const profDetails = JSON.parse(asset_arp_data)?.professional_details || {}
    const arpWeightageKeys = [
      {
        label: locale['Qualification'],
        values: 'qualification',
      },
      {
        label: locale['Relevant Certificates'],
        values: 'relavant_certificates',
      },
      {
        label: locale['Total Certificates'],
        values: 'total_certificates',
      },
      {
        label: locale['Relevant Work Experience'],
        values: 'relavant_work_experience',
      },
      {
        label: locale['Total Work Experience'],
        values: 'total_work_experience',
      },
    ]
    const allRows = []
    for (let i of arpWeightageKeys) {
      allRows.push(
        <Tr>
          <Td pl={0} pr={0} pt={2} pb={2} w="16em">
            {i.label}
          </Td>
          <Td textAlign="center" pl={0} pr={0} pt={2} pb={2}>
            {profDetails[i.values]}
          </Td>
        </Tr>
      )
    }
    return allRows
  }

  return (
    <>
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton p={0} mt={2} _focus={{ boxShadow: 'none' }}>
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <Th pl={0} pr={0} w="20em">
                    {locale['Entity']}
                  </Th>
                  <Th pl={0} pr={0}>
                    {locale['EAS Values']}
                  </Th>
                </Tr>
              </Thead>
            </Table>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={0}>
            <Table size="sm" variant="striped">
              <Tbody>{getTableRow()}</Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default EASScores
