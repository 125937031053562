import {
  Collapse,
  Spacer,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { IoMdMail, IoMdMailOpen } from 'react-icons/io'
import { useUpdateReadStatus } from '../../hooks/broadcast.hooks'
import AlertBox from '../AlertBox'
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  sectionDetailedTrack,
  GA_CATEGORY_DIALOG_BOX,
} from '../../containers/repository/repo.utils'
import { convertPound, convertUnicode } from '../../utils/linkInText.utils'

const BroadCastItem = ({onClose, msg, index, refresh }) => {
  const { mutate } = useUpdateReadStatus()
  const { isOpen: expand, onToggle } = useDisclosure()
  const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
  const [showMessage, setShowMessage] = useState(false)
  
  const onExpand = d => {
    onToggle()
    mutate(
      {
        broadcast_id: d.broadcast_id,
        broadcast_flag_read: 1,
        flag: 1,
      },
      {
        onSuccess: async res => {
          refresh()
        },
      }
    )
  }

  const customClose = () => {
    setShowMessage(false)
    alertClose()
    onClose()
    // loadAllForms();
  }

  return (
    <>
      <HStack
        justifyContent="space-between"
        boxShadow="md"
        key={index}
        borderRadius="0"
        w="full"
        px={2}
      >
        <IconButton
          my={2}
          icon={
            <Icon
              as={msg.broadcast_flag_read ? IoMdMailOpen : IoMdMail}
              h={6}
              w={6}
            />
          }
          color={localStorage.getItem('color')}
          _focus={{
            color: localStorage.getItem('color'),
          }}
          _hover={{
            color: 'white',
            bg: localStorage.getItem('color'),
          }}
          _active={{
            color: 'white',
            bg: localStorage.getItem('color'),
          }}
          onClick={() => {
            setShowMessage(true)
          }}
        />
        <VStack
          flex={1}
          p={2}
          alignItems="flex-start"
          wordBreak="break-all"
          overflow="hidden"
        >
          <Text
            fontSize="sm"
            alignSelf="flex-start"
            m={0}
            p={0}
            color="brand.800"
            textTransform="capitalize"
          >
            {msg.broadcast_subject}
          </Text>
          <Divider />
          <Collapse
            alignSelf="flex-start"
            startingHeight={20}
            in={expand}
            w="100%"
          >
            <Text
              alignSelf="flex-start"
              textAlign="left"
              style={{ margin: '0 !important' }}
              fontSize="sm"
            >
              {msg.broadcast_content}
            </Text>
          </Collapse>
          <HStack justifyContent="space-between" alignItems="center" w="full">
            <Spacer />
            <Text fontSize="10px" color="brand.800" alignSelf="flex-end">
              {format(
                new Date(msg.log_datetime.split(' ').join('T')),
                'dd MMM yyyy, HH:mm'
              )}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      {showMessage ? (
        <Modal isOpen={true} onClose={customClose}>
          <ModalOverlay />
          <ModalContent borderRadius="sm">
            <ModalHeader textAlign="center" fontSize="lg" fontWeight="400">
              <Text fontSize="15px" fontWeight="black">
                {msg.broadcast_subject}
              </Text>
              <hr />
              <Text
                fontSize="12px"
                dangerouslySetInnerHTML={{
                  __html: convertPound(
                    convertUnicode(unescape(msg.broadcast_content))
                  ),
                }}
              />
            </ModalHeader>
            <ModalFooter
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Button
                borderRadius="sm"
                size="sm"
                variant="solid"
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Alert Box',
                    label: 'Close Dialog',
                  })
                  customClose()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default BroadCastItem
