import { LogLevel } from '@azure/msal-browser'
import { isLocalhost } from './utils/common.util'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
	auth: {
		// 'Application (client) ID' of app registration in Azure portal - this value is a GUID
		clientId: '233e0427-b1a9-4f2a-9f8a-10a3578d7949',
		// Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
		authority:
			'https://login.microsoftonline.com/311b3378-8e8a-4b5e-a33f-e80a3d8ba60a',
		// Full redirect URL, in form of http://localhost:3000
		redirectUri: isLocalhost()
			? 'http://localhost:3000/files'
			: process.env.REACT_APP_MSAL_REDIRECTURI,
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message)
						return
					case LogLevel.Info:
						console.info(message)
						return
					case LogLevel.Verbose:
						console.debug(message)
						return
					case LogLevel.Warning:
						console.warn(message)
						return
					default:
						return
				}
			},
		},
	},
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: ['User.Read', 'Calendars.ReadWrite', 'Mail.Send'],
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
	scopes: ['User.Read', 'Mail.Read'],
	forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
	graphMailEndpoint: 'https://graph.microsoft.com/v1.0/me/messages',
	graphCalendarEndPoint: 'https://graph.microsoft.com/v1.0/me/calendar/events',
}
