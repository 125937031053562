import React, { useState } from 'react'
import {
	useDisclosure,
	Divider,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	Text,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Button,
	Input,
	Center,
	Box,
} from '@chakra-ui/react'
import {
	GA_CATEGORY_DIALOG_BOX,
	sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
const CreateRenameModal = ({
	type,
	name,
	onSubmit,
	onOpen,
	onClose,
	validation = true,
}) => {
	const [isOpen, setIsOpen] = useState(onOpen)
	const [docName, setDocName] = useState(name)
	const [error, setError] = useState()
	const {
		state: { locale },
	} = useLanguageContext()
	//   useState(() => {
	//     setIsOpen(isOpenDialog);
	//   }, [isOpenDialog]);
	const onFormSubmit = () => {
		let value = docName
		if (!validation) {
			onSubmit(docName)
			return
		}
		let regEx = /^([a-zA-Z0-9 _-]+)$/
		if (!!regEx.test(value)) {
			onSubmit(docName)
		} else {
			setError('No special char allowed')
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(false)
				onClose()
			}}
		>
			<ModalOverlay />
			<ModalContent borderRadius='sm'>
				<ModalHeader>
					<Text fontWeight='400'>
						{name === ''
							? locale['Create New']
							: locale['Change Activity Title']}{' '}
						{type}
					</Text>
				</ModalHeader>
				<Divider />
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl>
						<FormLabel>
							{type} {locale['Name']}
						</FormLabel>
						<Input
							value={docName}
							autoFocus={true}
							placeholder={locale['Name']}
							required
							onChange={e => {
								setDocName(e.target.value)
							}}
						/>
						{!!error && <Text color='red'>{error}</Text>}
					</FormControl>
					<Box p={5}>
						<Center>
							<Button
								size='md'
								minW='180px'
								variant='solid'
								isDisabled={docName === ''}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Create Rename Modal',
										label: 'Submit Rename Of Modal',
									})
									onFormSubmit()
								}}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								mr={3}
								borderRadius='sm'
							>
								{locale['Submit']}
							</Button>
						</Center>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
export default CreateRenameModal
