import React from 'react'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react'
import {
  sectionDetailedTrack,
  GA_CATEGORY_DIALOG_BOX,
} from '../../containers/repository/repo.utils'

export const BreadcrumbMenu = React.memo(({ menu, icon, onSelect }) => {
  return (
    <Box>
      <Breadcrumb spacing="8px" bg="whitesmoke" separator={icon ? icon : '/'}>
        {menu &&
          menu.length > 0 &&
          menu.map((link, index) => {
            return (
              <BreadcrumbItem
                key={link.id}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Breadcrumb Menu',
                    label: 'Select Item in Menu',
                  })
                  onSelect(link, index)
                }}
                p={2}
                borderRadius="md"
              >
                <BreadcrumbLink href="#">{link.label}</BreadcrumbLink>
              </BreadcrumbItem>
            )
          })}
      </Breadcrumb>
    </Box>
  )
})
export default BreadcrumbMenu
