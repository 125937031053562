import {
  Box,
  Table,
  Tbody,
  Text,
  Thead,
  Tfoot,
  Tr,
  Td,
  Th,
  TableContainer,
} from '@chakra-ui/react'
import React from 'react'
import { useLanguageContext } from '../../context/locale.context'
import { toReturnLeavesCount } from '../../utils/common.util'

const LeavesTable = ({ leaveData }) => {
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <Box width="100%">
      {leaveData && leaveData.length > 0 ? (
        <TableContainer boxShadow="md">
          <Table size="md">
            <Thead bg={localStorage.getItem('color')}>
              <Tr padding="20px">
                <Th>
                  <Text color="white" fontSize="sm" fontWeight="600">
                    {' '}
                    {locale['Leave Type']}
                  </Text>
                </Th>
                <Th>
                  <Text
                    color="white"
                    fontSize="sm"
                    fontWeight="600"
                    align="right"
                  >
                    {locale['Allocated']}
                  </Text>
                </Th>
                <Th>
                  <Text
                    color="white"
                    fontSize="sm"
                    fontWeight="600"
                    align="right"
                  >
                    {locale['Utilised']}
                  </Text>
                </Th>
                <Th>
                  <Text
                    color="white"
                    fontSize="sm"
                    fontWeight="600"
                    align="right"
                  >
                    {locale['Available']}
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {leaveData.map((val, i) => (
                <Tr key={i}>
                  <Td>
                    {' '}
                    <Text> {val.leave_type_name} </Text>
                  </Td>
                  <Td isNumeric>
                    <Text> {val.leaves_allocated}</Text>
                  </Td>
                  <Td isNumeric>
                    <Text>{val.leaves_used}</Text>
                  </Td>
                  <Td isNumeric>
                    <Text>{val.leaves_unused}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot bg="white">
              <Tr>
                <Td isNumeric>
                  <Text textAlign="left"> {locale['Total']} </Text>
                </Td>
                <Td isNumeric>
                  <Text>
                    {toReturnLeavesCount(leaveData, 'leaves_allocated')}{' '}
                  </Text>
                </Td>
                <Td isNumeric>
                  <Text>{toReturnLeavesCount(leaveData, 'leaves_used')} </Text>
                </Td>
                <Td isNumeric>
                  <Text>
                    {toReturnLeavesCount(leaveData, 'leaves_unused')}{' '}
                  </Text>
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  )
}

export default LeavesTable
