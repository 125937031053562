/* eslint-disable react-hooks/exhaustive-deps */
import {
	HStack,
	Tag,
	Divider,
	Flex,
	Spacer,
	Box,
	Icon,
	IconButton,
	Text,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { MdNotifications } from 'react-icons/md'
import {
	useBroadcastMessagecount,
	useGetBroadcastMessage,
} from '../../hooks/broadcast.hooks'
import { useLanguageContext } from '../../context/locale.context'
import BroadCastItem from './item'
import { useSharedContext } from '../../context/shared.context'

const BroadCastStatic = props => {
	const { mutate, isLoading, data: broadcastList } = useGetBroadcastMessage()
	const { mutate: messageCountMutate } = useBroadcastMessagecount()
	const firstFieldRef = React.useRef(null)

	const {
		state: { refreshBroadCast },
		dispatch,
	} = useSharedContext()

	const [msgCount, setMsgCount] = useState({})
	const {
		isOpen: isShowMessage,
		onOpen: onOpenShowMessage,
		onClose: onCloseShowMessage,
	} = useDisclosure()
	const {
		state: { locale },
	} = useLanguageContext()

	const reloadCount = () => {
		messageCountMutate(
			{},
			{
				onSuccess: async res => {
					console.log(res)
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						setMsgCount(response[0])
					}
				},
			}
		)
	}

	const refresh = () => {
		mutate({
			flag: 0,
		})
		reloadCount()
	}

	useEffect(() => {
		refresh()
	}, [refreshBroadCast])

	if (!!broadcastList && broadcastList.length === 0) {
		return null
	}

	return (
		<Box borderRadius='md' w='400px' boxShadow='md'>
			<HStack borderTopRadius='md' bg='white' minH='50px' px={2}>
				<Flex justifyContent='center'>
					<Icon
						as={MdNotifications}
						w={6}
						h={6}
						color={localStorage.getItem('color')}
					/>
					<Text fontSize='md' fontWeight='600' ml={2}>
						{locale['Broadcast Messages']}
					</Text>
				</Flex>
				<Spacer />
				<Tag size='md' color={localStorage.getItem('color')}>
					{msgCount.unread_count}
				</Tag>
			</HStack>
			<Divider />
			<VStack
				bg='white'
				borderBottomRadius='md'
				maxH='calc(100vh - 402px)'
				overflowY='scroll'
				w='full'
				py={2}
				px={4}
			>
				{(broadcastList || [])
					.sort((d, c) => d.broadcast_flag_read - c.broadcast_flag_read)
					.map((n, i) => (
						<BroadCastItem
							onClose={onCloseShowMessage}
							msg={n}
							refresh={reloadCount}
							index={i}
							key={i}
						/>
					))}
			</VStack>
		</Box>
	)
}

export default BroadCastStatic
