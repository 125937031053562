import { ChakraProvider } from '@chakra-ui/react'

import React from 'react'
import { QueryClientProvider } from 'react-query/react'
import { QueryClient } from 'react-query/core'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import Routes from './routes'
import store from './store'
import { history } from './store/history'
import theme from './theme'
import { uuid } from 'uuidv4'
import { AuthContextProvider } from './context/auth.context'
import { DeepStreamContextProvider } from './context/deepstream.context'
import { LanguageContextProvider } from './context/locale.context'
import '@fontsource/open-sans'
import '@fontsource/roboto'
// import TagManager from 'react-gtm-module'
import { DeepstreamClient } from '@deepstream/client'
import Lttstheme from './theme/index-ltts'

// const tagManagerArgs = {
// 	gtmId: process.env.REACT_APP_GTM_ID,
// }

// TagManager.initialize(tagManagerArgs)

const client = new QueryClient({})

const ds = new DeepstreamClient(process.env.REACT_APP_DEEPSTREAM_URL)
ds.login()

function App() {
	return (
		<ChakraProvider
			theme={!!process.env.REACT_APP_AD_USER_POOL_ID ? Lttstheme : theme}
		>
			<QueryClientProvider client={client}>
				<BrowserRouter>
					<AuthContextProvider>
						<DeepStreamContextProvider ds={ds}>
							<LanguageContextProvider>
								<Routes />
							</LanguageContextProvider>
						</DeepStreamContextProvider>
					</AuthContextProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</ChakraProvider>
	)
}

export default App
