import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import {
  sectionDetailedTrack,
  GA_CATEGORY_DIALOG_BOX,
} from '../../containers/repository/repo.utils'

export const AlertBox = ({ isOpen, onClose, Message }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader textAlign="center" fontSize="lg" fontWeight="400">
          {Message}
        </ModalHeader>
        <ModalFooter alignItems="center" justifyContent="center" display="flex">
          <Button
            borderRadius="sm"
            size="sm"
            variant="solid"
            colorScheme={localStorage.getItem('color')}
            bg={localStorage.getItem('color')}
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Alert Box',
                label: 'Close Dialog',
              })
              onClose()
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default AlertBox
