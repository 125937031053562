import React, { useState } from 'react'
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormHelperText,
} from '@chakra-ui/react'
import { ShortText } from '../../components/form/components'
import { toSnakeCase } from '../../utils/common.util'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'

const FieldConfig = {
  19: ShortText,
}

function UpdateDupName({ onClose, isOpen, duplicateError, fieldList, onSave }) {
  const [isValid, setValid] = useState(false)
  const [error, setError] = useState('')
  const [curr_index, setCurrIndex] = useState(0)

  const acc_field = !!fieldList[0] ? fieldList[0] : {}
  const pan_field =
    !!fieldList &&
    fieldList.filter(s => {
      return s.field_name.match(/pan number/gi)
    })
  const gst_field =
    !!fieldList &&
    fieldList.filter(s => {
      return s.field_name.match(/gst number/gi)
    })
  const pan_gst_fields = [...pan_field, ...gst_field]
  const fields =
    !!duplicateError && duplicateError.toLowerCase().includes('pan')
      ? !!pan_gst_fields
        ? pan_gst_fields
        : [acc_field]
      : [acc_field]

  const field = fields[curr_index]

  const onValidate = e => {
    setValid(e)
  }
  const onError = e => {
    setError(e)
  }
  const onContinue = () => {
    setCurrIndex(curr_index + 1)
  }
  const onPrev = () => {
    setCurrIndex(0)
  }
  const onSaveField = () => {
    setCurrIndex(0)
    onSave()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader fontSize="lg" fontWeight="400">
          Update Duplicate Account
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <FormControl key={field.field_id} id={`update-field`}>
            <FormLabel
              className={`field-item-icon-button-${toSnakeCase(
                field.field_name
              )}`}
              fontWeight="400"
              fontSize="md"
              my={1}
            >{`Enter ${field.field_name}`}</FormLabel>
            {!!FieldConfig[field.data_type_id] &&
              React.createElement(FieldConfig[field.data_type_id], {
                key: field.field_id,
                field: field,
                isDisabled: false,
                isRequired: true,
                onValidate,
                onError,
                skip: null,
                focus: true,
              })}
            {!!error && (
              <FormHelperText color="red.300" fontWeight="400" fontSize="12px">
                {error}
              </FormHelperText>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {curr_index !== 0 && (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              mr={3}
              isDisabled={false}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Update Duplicate Account',
                  label: 'Go Previous',
                })
                onPrev()
              }}
            >
              Prev
            </Button>
          )}
          {curr_index + 1 === fields.length ? (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              mr={3}
              isDisabled={!isValid}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Update Duplicate Account',
                  label: 'Submit',
                })
                onSaveField()
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              mr={3}
              isDisabled={!isValid}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Update Duplicate Account',
                  label: 'Continue',
                })
                onContinue()
              }}
            >
              Next
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default UpdateDupName
